:root {
    --theme-black: rgb(35,31,32);
    --theme-white: whitesmoke;
}

body, html, #root {
    margin: 0;
    padding: 0;
    height: 100%;
    overflow-x: hidden;
    background-color: var(--theme-white);
}

ul {
    list-style-type: none;
}

ul>li:before{
    display: inline-block;
    content: "-";
    width: 1em;
    margin-left: -1em;
}

ul>li.no-dash:before {
    content: "";
}

.nav-text{
    display: block;
    color: var(--theme-black);
    text-decoration: none;
    position: relative;
}

.nav-link::after{
    content: "";
    background: #000000;
    mix-blend-mode: overlay;
    width: calc(100% + 20px);
    height: 0;
    position: absolute;
    bottom: -4px;
    left: -10px;
    transition: all .4s cubic-bezier(0.445, 0.05, 0.55, 0.95);
}

.nav-link:hover::after{
    height: calc(100% + 8px);
}

a.regularLink{
    color: #0047AB;
}

a.regularLink:visited{
    color: darkcyan
}

a.noDecoration, a.noDecoration:hover, a.noDecoration:focus, a.noDecoration:active {
    text-decoration: none;
    color: inherit;
}